// en.js
const arTranslations = {

    main: 'تمرات',
    main2:'نقدم تشكيلة واسعة من التمور الأصلية بجودة عالية. تصفح موقعنا الآن للاطلاع على تشكيلتنا المتنوعة من التمور، بما في ذلك التمور السكرية وتمور العجوة وتمر الخلاص وغيرها الكثير. تتوفر لدينا خدمة التوصيل السريع إلى جميع أنحاء العالم، ونضمن لك جودة تمورنا في جميع الأوقات. تسوق الآن واحصل على أفضل التمور',
    main3:'تسوق الان',

    home1:'الأكثر مبيعا',
    home2:'Best Sellers',
    home3:'شراء سريع',
    home4:'تفاصيل اكثر',
    home5:'إضافة الى السلة',
    home6:'تسوق باقي المنتجات',

    home7:'تمورنا',
    home8:'احصل على أفضل أنواع التمور من المزارعين المحليين في المملكة العربية السعودية، وتمتع بجودة عالية وتغليف فريد لنضمن وصول التمور إليك بأفضل حالاتها. استمتع بتجربة تسوق رائعة واختر من بين تشكيلتنا المتنوعة من التمور الطازجة، بما في ذلك التمور السكرية والعجوة . نحرص على تقديم أفضل أنواع التمور ا، ونحرص على تقديمها لك بتغليف أنيق. تسوق الآن واحصل على تمور طازجة ولذيذة مباشرة من المزارعين المحليين في المملكة العربية السعودية',
    home9:'تسوق الان',
    home10:'التمور',
    home11:'ارث من الاف السنين',
    home12:'تسوق الان',
    home13:'من قيم الكرم والضيافة',
    home14:'عنصر غذائي مهم',
    home15:'لماذا تمرات',
    home16:'تمور جودة عالية',
    home17:'تمور طازجة',
    home18:'تمور مختارة بعناية',
    home19:'تسوق الان',
    home20:'أهدي من تحب بتشكيلة مميزة من تمورنا بأصناف وأحجام مختلفة',
    home21:'تسوق الان',
    home22:'فوائد التمر',
    home23:'غني بالألياف',
    home24:'مضادات الأكسدة',
    home25:'خالي من الجلوتين',
    home26:'طبيعي',

    header1:'الرئيسية',
    header2:'التمور',
    header3:'تمر العجوة',
    header4:'تمر السكري',
    header5:'تمر المجدول',
    header6:'تمر المبروم',
    header7:'تمر صقعي',
    header8:'احجام التمور',
    header9:'المدونة',
    header10:'تواصل معنا',

    sar:'ر.س',
    reviews:'تقيمات',
    price:'السعر: ',

    status:'الحالة',
    in:'متوفر',
    out:'نفذت الكمية',
    qty:'الكمية',
    add:'إضافة الى السلة',
    back:'العودة للخلف',

    cart1:'سلة التسوق',
    cart2:'لا يوجد منتحات في السلة',
    sub:'المجموع',
    items:'منتجات',
    checkout:'المتابعة لإنهاء الطلب',
    
    step1:'تسجيل الدخول',
    step2:'معلومات الشحن',
    step3:'معلومات الدفع',
    step4:'إنهاء الطلب',

    shipping1:'معلومات الشحن',
    shipping2:'العنوان',
    shipping3:'ادخل العنوان',
    shipping4:'المدينة',
    shipping5:'ادخل المدينة',
    shipping6:'الرمز البريدي',
    shipping7:'ادخل الرمز البريدي',
    shipping8:'الدولة',
    shipping9:'اختر الدولة',
    shipping10:'التالي',
    shipping15:'اختر المدينة',


    payment1:'معلومات وسيلة الدفع',
    payment2:'اختر طريقة الدفع',
    payment3:'دفع إلكتروني',
    payment4:'تحويلات بنكية',

    order1:'معلومات الشحن',
    order2:'العنوان:',
    order3:'معلومات الدفع',
    order4:'وسيلة الدفع:',
    order5:'منتجات الطلب',
    order6:'ملخص الطلب',
    order7:'سعر المنتجات',
    order8:'الشحن',
    order9:'الخصم',
    order10:'المجموع',
    order11:'إنهاء الطلب',

    auth1:'التسجيل وتسجيل الدخول',
    auth2:'تسجيل الدخول عبر رسالة نصية',
    auth3:'تسجيل الدخول عبر الايميل',
    login:'تسجيل الدخول',
    register:'التسجيل',
    enterPhone:'ادخل رقم الجوال',
    enterEmail:'ادخل الايميل',
    continue:'المتابعة',
    new:'إنشاء حساب جديد',
    checkOtp:'التحقق من رمر تسجيل الدخول',
    enterOtp:'ادخل الرمز الذي تم ارساله لك',
    check:'التحقق',

    faqTitle: "الأسئلة الشائعة",
    faqQuestion1: "يحتوي التمر على نسبة كبيرة من السكر فكيف يكون فاكهة صحية؟",
    faqAnswer1: "إن السكر الموجود بالتمر – في شكله الأصلي – مختلف عن أنواع السكر الأخرى. فهو بطئ الامتصاص مقارنة بغيره وذلك لاحتواءه على الألياف بشكل مرتفع. حيث لا يتطلب ذلك إنتاج البنكرياس للمزيد من الإنسولين، مما يجعل التمر الخيار الأمثل للإفطار. فهو يمد الجسم بالطاقة فوريا دون ضرر. بينما يختلف التمر الموجود في كرات الطاقة مثلا عن التمر بشكله الأصلي. وذلك يعود إلى تكسر الألياف الموجودة بالتمر حيث لا يمكن للبنكرياس حينها التمييز بين أنواع السكر مما يرفع مستوى الأنسولين بالدم.",
    faqQuestion2: "ماهي الفوائد الصحية لتناول التمر؟",
    faqAnswer2: "نشرت العديد من الأبحاث والدراسات في المواقع العلمية مثل Pubmed و Google scholar اللي تثبت فوائد التمر الصحية وقدراته العلاجية. ذكرت من خلالها قدرة التمر على تخفيف آلام الحمل، وتقليل التأثيرات العصبية، والحماية من العديد من السرطانات، بالإضافة إلى احتواءه على مضادات الأكسدة والإلتهابات.",
    faqQuestion3: "ما هي أنواع التمر وكيف تختلف؟",
    faqAnswer3: "يوجد أكثر من 600 نوع للتمور حول العالم، وتختلف في مذاقها، وملمسها، ولونها، وحتى فوائدها الغذائية. فلكل نوع ما يميزه عن الأنواع الأخرى من الصفات. على سبيل المثال: تمر المجدول أو المجهول له حلاوة طاغية، وهو أكثر الأنواع شهرة واستهلاكا في المملكة المتحدة وأوروبا وأمريكا الشمالية. من الأصناف الشائعة أيضا تمور السكري الطازجة الأكثر رواجا في منطقة الخليج العربي، وتتواجد بشكل رئيسي في المملكة العربية السعودية. تتميز تمور السكري بخفة حلاوتها مقارنة بتمر المجدول، ونعومة ملمسها، ونكهتها الجوزية والكريمية. بالإضافة إلى تمور الخضري ذات الملمس الناعم والطري، تحظى بشعبية بين المجتمعات الآسيوية. تمر الخلاص الذي يعد من أفضل التمور بالمملكة العربية، يتميز بطعم التوفي اللذيذ وقوامه العسلي الذي لا يقاوم. تمر العجوة الذي يشتهر بلونه الداكن، ونعومة ملمسه، وحلاوة طعمه، يتميز بالعديد من الخصائص الطبية ويزرع بشكل خاص في المدينة المنورة. وهو الأكثر شهرة والأغلى ثمنا والأعلى بفوائده الغذائية من بين جميع أنواع التمر الأخرى.",
    faqQuestion4: "ماهو أفضل وقت لتناول التمر؟",
    faqAnswer4: "التمر مناسب في جميع الأوقات سواء في الصباح أو المساء. يكون مثالي مع القهوة العربية في الصباح أو قبل البدء بمجهود عضلي.",

    "contact": {
      "title": "كن علي تواصل معنا",
      "description": "نسعد دوما بتواصلكم معنا",
      "location": "الموقع",
      "locationDetails": "المملكة العربية السعودية - جدة",
      "phoneNumber": "رقم الهاتف",
      "email": "البريد الالكتروني",
      "beInTouch": "كن علي تواصل",
      "name": "الأسم",
      "emailPlaceholder": "البريد الالكتروني",
      "phoneNumberPlaceholder": "رقم الجوال",
      "message": "نص الرسالة",
      "send": "أرسل"
    },


    "Tamrat Dates": "تمرات",
    "About Us": "من نحن",
    "Our Products": "منتجاتنا",
    "From Farm to Table": "من المزرعة إلى الطاولة",
    "Benefits of Dates": "فوائد التمر",
    "History of Dates": "تاريخ التمور",
    "About The Company": "عن الشركة",
    "Contact Us": "اتصل بنا",
    "Frequently Asked Questions": "الاسئلة الشائعة",
    "Hiring": "التوظيف",
    "Ajwa Date": "تمر عجوة",
    "Sukari Date": "تمر سكري",
    "Sagie Date": "تمر صقعي",
    "Majhool Date": "تمر مجدول",
    "Mabroom Date": "تمر مبروم",
    "Commercial Register No. 4030299562": "السجل التجاري رقم 4030299562",
    "Privacy Policy": "سياسة الخصوصية",
    "Return Policy": "سياسة الإرجاع",
    "Sitemap": "خريطة الموقع",
    "© 2023 Tamrat Dates All Rights Reserved": "© 2023 تمرات. كل الحقوق محفوظة",
    "bankName": "اسم البنك",
    "bankCode": "رمز البنك",
    "accountNumber": "رقم الحساب",
    "ibanNumber": "رقم الأيبان",
    "sabb": "SABB",
    "sabbSari": "SABBSARI",

    place1:'الطلب',
    place2:'معلومات الشحن',
    place3:'الاسم: ',
    place4:'الايميل: ',
    place5:'العنوان: ',
    place6:'لم يتم التوصيل',
    place7:'معلومات طريقة الدفع',
    place8:'وسيلة الدفع: ',
    place9:'لم يتم الدفع',
    place10:'المنتجات',
    war:'تم تسليم ايصال الدفع جاري المراجعة',
    ajwaTitle:'تمور عجوة المدينة عضوي',
    mabroomTitle:'تمر مبروم الطبيعي',
    majhoolTitle:'تمر مجدول الفاخر والمميز',
    sagieTitle:'تمر صقعي',
    sukariTitle:'تمر سكري طبيعي وطازج',
    freeShipping:'شحن مجاني للطلبات فوق 250 ريال',


    header20:'القائمة الرئيسية',

    header21:'اللغة',

    header22:'تسجيل خروج',
    header24:'جميع التمور',

    acc1:'سلة التسوق',
  acc2:'المجموع: ',
  acc3:'الانتقال الى السلة',

  metaTitle:'تمرات : اكتشف تشكيلتنا الواسعة من تمر سكري،تمر صقعي،عجوة المدينة والمزيد.',
  metaDes:'تمرات هو متجر الكتروني لبيع التمور الطازجة والعضوية. نقدم تمور السكري، الخلاص، وعجوة المدينة بالإضافة الى التمور المحشوة الغنية بالألياف والفيتامينات الأساسية',


  coupon1:'هل لديك كود خصم ؟',
  coupon2:'الكوبون',
  coupon3:'خصم',

  payConfirm:'الدفع وتاكيد الطلب',
  sumbitRe:'رفع ايصال الدفع',
  reImage:'اختر صورة',
  news1:'اشترك في القائمة البريدية',
  news2:'البريد الالكتروني',
  news3:'اشترك',

  success1:'تمت عملية الدفع بنجاح ، شكرا لاختيارك تمرات',
  success2:'الانتقال الى صفحة الطلب',
  success3:'الرئيسية',
  fail1:'فشلت عملية الدفع ، الرجاء المحاولة مرة اخرى',

  box:'علبة - حبة واحدة ، اقل كمية للطلب ٥٠ علبة',
  box1:'علبة - حبة واحدة',
  kg:'كيلو',
  half:'نصف كيلو',
  seven:'علبة - ٧ حبات',

  sizePage:'احجام علب التمور',
  faqPage:'الاسئلة الشائعة',
  find:'إبحث عن منتج ...',
  resu:'نتائج البحث عن :',
  val:'يرجى تسجيل الدخول او التسجيل للمتابعة',
  val2:'تسجيل الدخول او التسجيل',
  checkOtpNew:'تم ارسال رمز التحقق الى ',

  registeration1:'الاسم',
  registeration2:'البريد الالكتروني',
  registeration3:'ادخل رقم الجوال',
  registeration4:'التسجيل',
  registeration5:'هل لديك حساب بالفعل ؟',

  sim:'منتجات ذات صلة',
  account1:'حساب المستخدم',
  account2:'الاسم',
  account3:'الايميل',
  account4:'الجوال',
  account5:'طلباتي',

  account6:'الحساب',
  account7:'تسجيل خروج',

  download:'تحميل الفاتورة',
  paidDone:'عملية الدفع تمت بنجاح',
  delDone:'تم التوصيل',
  pr1:'التاريخ',
  pr2:'المجموع',
  pr3:'حالة الدفع',
  pr4:'حالة الشحن',
  pr5:'التفاصيل',
  noOrders:'ليس لديك طلبات بعد',
  place120:'تم تجهيز الشحنة واصدار بوليصة الشحن',

  shipform1:'سأقوم باستلام الشحنة بنفسي',
  shipform2:'سيقوم شخص اخر باستلام الشحنة',
  shipform3:'إرسال كهدية الى شخص',

  shipform4:'الاسم',
  shipform5:'ادخل الاسم',
  shipform6:'رقم الجوال',
  shipform7:'ادخل رقم الجوال',
  shipform8:'الرسالة',
  shipform9:'اكتب الرسالة',

  orderType:'استلام الطلب',
  GiftMessage:'نص الرسالة',

  de:'سيقوم العميل باستلام الطلب بنفسه',
  de1:'سيقوم شخص اخر باستلام الطلب',
  de2:'ارسال الطلب كهدية',

  enterName:'ادخل اسمك',
  };
  
  export default arTranslations;
  